import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
} from "reactstrap"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Header = styled(Navbar)``
const Wrapper = styled(Row)`
  width: 1140px;
`

export default class Example extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <Header fluid>
        <Wrapper>
          <Col>
            <Navbar light expand="md">
              <NavbarBrand href="/">
                <Link to="/">
                  <div className="logoWrapper"></div>
                </Link>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <Link className="nav-link" to="#howItWorks">
                      How It Works
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="#subscribe">
                      Subscribe
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className="nav-link" to="/blog/">
                      Blog
                    </Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Wrapper>
      </Header>
    )
  }
}
