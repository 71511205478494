import React from "react"
import { ThemeProvider } from "styled-components"
import { Container } from "reactstrap"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const theme = {
  main: {
    fonts: {
      headingOne: {
        fontFamily: "Domine",
        fontSize: "48px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.11",
        color: "#000000",
      },
      headingTwo: {
        fontFamily: "Domine",
        fontSize: "36px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.32",
      },
      headingThree: {
        fontFamily: "Libre Franklin",
        fontSize: "18px",
        fontWeight: "normal",
        fontWeight: "500",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "normal",
        color: "#3a3a3a",
      },
      headingFour: {
        color: "#3a3a3a",
        fontFamily: "Libre Franklin",
        fontSize: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "2.25",
        color: "#3a3a3a",
      },
      headingPromo: {
        fontFamily: "Domine",
        fontSize: "66px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.11",
        paddingTop: "65px",
      },
      headingBlog: {
        fontFamily: "Domine",
        fontSize: "36px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.32",
      },
      headingMailList: {
        fontFamily: "Domine",
        fontSize: "36px",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.32",
      },
      paragraphOne: {
        fontFamily: "Libre Franklin",
        fontSize: "22px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.5",
        color: "#3a3a3a",
      },
      paragraphTwo: {
        fontFamily: "Libre Franklin",
        fontSize: "18px",
        fontWeight: "normal",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        lineHeight: "1.6",
        color: "#3a3a3a",
      },
    },
  },
}

export default ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header />
        <Container fluid>{children}</Container>
        <Footer />
      </div>
    </ThemeProvider>
  )
}
