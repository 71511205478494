import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"
import { Container, Row, Col } from "reactstrap"

const Footer = styled(Container)`
  padding: 30px 0 40px 0;
`
const LinkWrapper = styled.div`
  margin: 0 auto;
`
const LinkList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`
const LinkItem = styled.li`
  float: left;
`
const IconWrapper = styled.div`
  margin: 30px auto;
  padding: 0;
`
const CopyrightWrapper = styled(Col)`
  text-align: center;
  font-family: "Libre Franklin", sans-serif;
  color: #1d1b1b;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 30px 0 0 0;
  padding: 0;
`

export default () => {
  return (
    <Footer>
      <Row>
        <LinkWrapper>
          <LinkList>
            <LinkItem>
              <Link className="footerLink" to="/presskit/">
                Press Kit
              </Link>
            </LinkItem>
            <LinkItem>
              <Link className="footerLink" to="/privacy/">
                Privacy
              </Link>
            </LinkItem>
            <LinkItem>
              <Link className="footerLink" to="/terms/">
                Terms
              </Link>
            </LinkItem>
            <LinkItem>
              <a className="footerLink" href="mailto:support@savor.co">
                Support
              </a>
            </LinkItem>
          </LinkList>
        </LinkWrapper>
      </Row>
      <Row>
        <IconWrapper>
          <a
            className="iconLink"
            href="https://www.facebook.com/savorwellnessinc"
            target="_blank"
          >
            <FaFacebookSquare />
          </a>
          <a
            className="iconLink"
            href="https://www.instagram.com/savorwellnessco/"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            className="iconLink"
            href="https://twitter.com/food_savor"
            target="_blank"
          >
            <FaTwitter />
          </a>
        </IconWrapper>
      </Row>
      <Row>
        <CopyrightWrapper>&copy; 2020 SAVOR WELLNESS INC.</CopyrightWrapper>
      </Row>
    </Footer>
  )
}
